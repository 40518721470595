// Check to make sure service workers are supported in the current browser,
// and that the current page is accessed from a secure origin. Using a
// service worker from an insecure origin will trigger JS console errors. See
// http://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
var isLocalhost = Boolean(window.location.hostname === 'localhost' ||
window.location.hostname === '[::1]' ||
window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
)
);

function registerValidSW(swUrl) {
navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
        registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                        console.log('New content is available; please refresh.');
                    } else {
                        console.log('Content is cached for offline use.');
                    }
                }
            };
        };
    })
    .catch(error => {
        console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl) {
fetch(swUrl)
    .then(response => {
        if (
            response.status === 404 ||
            response.headers.get('content-type').indexOf('javascript') === -1
        ) {
            navigator.serviceWorker.ready.then(registration => {
                registration.unregister().then(() => {
                    window.location.reload();
                });
            });
        } else {
            registerValidSW(swUrl);
        }
    })
    .catch(() => {
        console.log(
            'No internet connection found. App is running in offline mode.'
        );
    });
}

export function register() {
if ('serviceWorker' in navigator) {
    var publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
        return;
    }

    window.addEventListener('load', function() {
        var swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        if (isLocalhost) {
            checkValidServiceWorker(swUrl);
        } else {
            registerValidSW(swUrl);
        }
    });
}
}

export function unregister() {
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
    });
}
}

