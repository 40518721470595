import React from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';

export default function PositionSelect({ control, onPositionChange, defaultValue }) {
  const options = [
    { value: 'Assistant', label: 'Assistant' },
    { value: 'Associate Photographer', label: 'Associate Photographer' },
    { value: 'Lead Photographer', label: 'Lead Photographer' },
       // More options...
  ];
  const handlePositionChange = (selectedOption, field) => {
    field.onChange(selectedOption.value); // Pass the value to field.onChange
    if (selectedOption) {
      onPositionChange(selectedOption.value); // your custom action
    }
  };

  

  return (
    <Controller
      name="position"
      control={control}
      defaultValue={defaultValue}
      rules={{ required: true }}
      render={({ field }) => (
        <ReactSelect
        {...field}
        options={options}
        isSearchable
        placeholder="Select Position"
        value={options ? options.find(option => option.value === field.value) : ''}
        onChange={(selectedOption) => handlePositionChange(selectedOption, field)}
      />
      
      )}
    />
  );
}
