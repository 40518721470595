import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

function JobSearch({ control, onJobChange }) {  // include onJobChange in the props
  const options = [
    { value: 'EPP', label: 'Private Session' },
    { value: 'BLA', label: 'Beacon Learning Academy' },
    { value: 'CA Action', label: 'Cary Academy Action' },
    { value: 'CA School', label: 'Cary Academy School' },
    { value: 'CA Sports', label: 'Cary Academy Sports' },
    { value: 'CDC', label: 'Carolina Dance Collaborative' },
    { value: 'ADMHS School', label: 'Athens Drive School' },
    { value: 'ADMHS Sports', label: 'Athens Drive Sports' },
    { value: 'DA Action', label: 'Durham Academy Action' },
    { value: 'DA Sports', label: 'Durham Academy Sports' },
    { value: 'MSR School', label: 'Montesorri School of Raleigh' },
    { value: 'MSR Sports', label: 'Montesorri School of Raleigh Sports' },
    { value: 'NRCA School', label: 'North Raleigh Christian Academy School' },
    { value: 'NRCA Sports', label: 'North Raleigh Christian Academy Sports' },
    { value: 'SCS School', label: 'Southside Christian School' },
    { value: 'SCS Sports', label: 'Southside Christian Sports' },
    { value: 'SCSWF School', label: 'Saint Catherine of Siena School' },
    { value: 'SCSWF Sports', label: 'Saint Catherine of Siena Sports' },
    { value: 'SMS Action', label: 'Saint Marys Action' },
    { value: 'SMS School', label: 'Saint Marys School' },
    { value: 'SMS Sports', label: 'Saint Marys Sports' },
    { value: 'TFS School', label: 'The Franciscan School' },
    { value: 'TFS Sports', label: 'The Franciscan Sports' },
    { value: 'TRS School', label: 'The Raleigh School' },
    { value: 'TSDCH Action', label: 'Trinity Durham and Chapel Hill Action' },
    { value: 'TSDCH School', label: 'Trinity School of Durham and Chapel Hill' },
    { value: 'TSDCH Sports', label: 'Trinity Durham and Chapel Hill Sports' },
   
  ];

  return (
    <>
      <Controller 
        name="job"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            placeholder="Select Job"
            {...field}
            options={options}
            value={options ? options.find(option => option.value === field.value) : ''}
            onChange={option => {
              field.onChange(option.value);  // existing onChange function
              onJobChange(option.value);  // call the onJobChange prop
            }}
          />
        )}
      />
    </>
  );
}

export default JobSearch;