import React from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import Cookies from 'js-cookie';

export default function NameSelect({ control, onNameChange }) {
  const options = [
    { value: 'Aidan Myers', label: 'Aidan M.' },
    { value: 'Brandie Baird', label: 'Brandie B.' },
    { value: 'Brooke McDuffee', label: 'Brooke M.' },
    { value: 'Cammel Hurse', label: 'Cammel H.' },
    { value: 'Christina Nguyen', label: 'Christina N.' },
    { value: 'Deming Haines', label: 'Deming H.' },
    { value: 'Emily Russel', label: 'Emily R.' },
    { value: 'Evan Pike', label: 'Evan P.' },
    { value: 'Gianna Ragogna', label: 'Gianna R.' },
    { value: 'Hope Bruns', label: 'Hope B.' },
    { value: 'Katie Campbell', label: 'Katie C.' },
    { value: 'Lynne Greig', label: 'Lynne G.' },
    { value: 'Mia Frost', label: 'Mia F.' },
    { value: 'Mike Forster', label: 'Mike F.' },
    { value: 'Sarah Thomas', label: 'Sarah T.' },
    { value: 'Savion Washington', label: 'Savion W.' },
    { value: 'Shauna Milian', label: 'Shauna M.' },
    // More options...
  ];

  const handleNameChange = (selectedOption, field) => {
    field.onChange(selectedOption.value); // Pass the value to field.onChange
    if (selectedOption) {
      onNameChange(selectedOption.value); // your custom action
      field.onBlur();
      Cookies.set('selectedUser', selectedOption.value); // Store selected value in a cookie
    }
  };

  const defaultValue = Cookies.get('selectedUser') || ''; // Fetch the value from the cookie if it exists

 
  return (
    <Controller
      name="selectedName"
      control={control}
      defaultValue={defaultValue} 
      rules={{ required: true }}
      render={({ field }) => (
        <ReactSelect 
          {...field}
          options={options}
          isSearchable
          placeholder="Select Name"
          value={options ? options.find(option => option.value === field.value) : ''}
          onChange={(selectedOption) => handleNameChange(selectedOption, field)}
        />
      )}
    />
  );
}
