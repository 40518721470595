import React from 'react';
import TimeTracker from './TimeTracker';

function App() {
  return (
  
      <TimeTracker />
  
  );
}

export default App;
