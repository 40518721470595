import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import JobSearch from './JobSearch';
import { db } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import './App.css';
import NameSelect from './NameSelect';
import PositionSelect from './PositionSelect';
import { doc, updateDoc } from "firebase/firestore";

function UserActivityDetails({ name, job, position, duration, startTime, endTime, isEditing, onStartChange, onEndChange }) {
  const formattedStartTime = startTime ? startTime.replace("T", " ") : startTime;
  const formattedEndTime = endTime ? endTime.replace("T", " ") : endTime;

  return (
    <div>
      <h2>UserActivityDetails</h2>
      <p><strong>Name:</strong> {name}</p>
      <p><strong>Job:</strong> {job}</p>
      <p><strong>Position:</strong> {position}</p>
      <p><strong>Total Time Worked:</strong> {duration} hours</p>
      <p><strong>Start Time:</strong> {formattedStartTime}</p>
      <p><strong>End Time:</strong> {formattedEndTime}</p>
    </div>
  );
}




function TimeTracker() {
  const { handleSubmit, control, setValue } = useForm();
  const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('formData')) || {});
  const [timerRunning, setTimerRunning] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [job, setJob] = useState(''); // state for storing the selected job
 // const [password, setPassword] = useState('');
  //const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [userActivityDetails, setUserActivityDetails] = useState(null);
  const [timerState, setTimerState] = useState('start'); // The initial state is 'start'
  const [name, setName] = useState(''); // state for storing the selected name
  const [isEditing, setIsEditing] = useState(false);
  const [newStartTime, setNewStartTime] = useState("");
  const [newEndTime, setNewEndTime] = useState("");
 const [isDetailsShown, setIsDetailsShown] = useState(false);



 // Add this function to handle changes in the start time input field
  const handleStartTimeChange = (event) => {
    setUserActivityDetails(prevState => ({ ...prevState, startTime: event.target.value }));
  };

  // Add this function to handle changes in the end time input field
  const handleEndTimeChange = (event) => {
    setUserActivityDetails(prevState => ({ ...prevState, endTime: event.target.value }));
  };


  useEffect(() => {
    const storedName = window.localStorage.getItem("name");
    if (storedName) {
      setValue("name", storedName);
    }
  }, [setValue]);

  useEffect(() => {
    for (const [key, value] of Object.entries(formData)) {
      setValue(key, value);
    }
  }, [formData, setValue]);

let startTimer = () => {
  const currentStartTime = new Date();
  setStartTime(currentStartTime);
  setTimerRunning(true);
  setTimerState('stop'); // Set the timer state to 'stop' after timer starts

  // Store start time in local storage
  localStorage.setItem('startTime', currentStartTime.getTime().toString());

  // Set new start time
  setNewStartTime(formatTimestamp(currentStartTime));
};

useEffect(() => {
  localStorage.setItem('timerRunning', JSON.stringify(timerRunning));
}, [timerRunning]);

useEffect(() => {
  const storedStartTime = localStorage.getItem('startTime');
  const storedTimerRunning = JSON.parse(localStorage.getItem('timerRunning'));

  if (storedStartTime) {
    setStartTime(new Date(parseInt(storedStartTime, 10)));
  }

  if (storedTimerRunning) {
    setTimerRunning(storedTimerRunning);
    setTimerState('stop');
  } else {
    setTimerRunning(false);
    setTimerState('start');
  }
}, []);

useEffect(() => {
  const storedStartTime = localStorage.getItem('startTime');

  if (storedStartTime) {
    setStartTime(new Date(parseInt(storedStartTime, 10)));
    setTimerRunning(true); // If there is a start time in local storage, that means the timer is running
    setTimerState('stop');
  }
}, []);

  
  const formatTimestamp = (timestamp) => {
    const year = timestamp.getFullYear();
    const month = ('0' + (timestamp.getMonth() + 1)).slice(-2);
    const day = ('0' + timestamp.getDate()).slice(-2);
    const hours = ('0' + timestamp.getHours()).slice(-2);
    const minutes = ('0' + timestamp.getMinutes()).slice(-2);
    const seconds = ('0' + timestamp.getSeconds()).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };
  

  const calculateDuration = (start, end) => {
    const diffInMilliseconds = end - start;
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    return parseFloat(diffInHours.toFixed(2));
  };

const stopTimer = async () => {
  setTimerRunning(false);
  let endTime = new Date();
  const duration = calculateDuration(startTime, endTime);
  const data = {
    ...formData,
    job, // include the job in the data
    startTime: formatTimestamp(startTime),
    endTime: formatTimestamp(endTime),
    duration, // include the duration in the data
  };

  for (const key in data) {
    if (key === '') {
      delete data[key];
    }
  }

  for (const value of Object.values(data)) {
    if (value === '') {
      console.log('Some fields in data are empty');
      return;
    }
    setIsDetailsShown(true);

  }

  const docRef = await addDoc(collection(db, 'timeRecords'), data);
  localStorage.removeItem('formData');
  localStorage.removeItem('startTime'); // Remove start time from local storage

  setUserActivityDetails({
    name: data.name,
    job: data.job,
    position: data.position,
    duration: data.duration,
    startTime: formatTimestamp(startTime),
    endTime: formatTimestamp(endTime),
    docId: docRef.id, // store the document id
  });

  // Set new end time
  setNewEndTime(formatTimestamp(endTime));
  
  setTimerState('reset'); // Set the timer state to 'reset' after timer stops
};

  

  const resetSession = () => {
    // Resets the state variables to their initial states
    setTimerRunning(false);
    setStartTime(null);
    setJob('');
    setFormData({});
    setUserActivityDetails(null);
    setTimerState('start'); // Set the timer state back to 'start' after reset

    localStorage.removeItem('startTime'); // Remove start time from local storage
    setIsDetailsShown(false);

};


const handleNameChange = (selectedName) => {
  setName(selectedName);
  localStorage.setItem('name', selectedName);
  const newFormData = {
    ...formData,
    name: selectedName,
  };
  setFormData(newFormData);
  localStorage.setItem('formData', JSON.stringify(newFormData));
};

useEffect(() => {
  const storedName = localStorage.getItem('name');
  if (storedName) {
    setName(storedName);
  }
}, []);

useEffect(() => {
  localStorage.setItem('name', name);
}, [name]);

  useEffect(() => {
    const storedJob = localStorage.getItem('job');
    if (storedJob) {
      setJob(storedJob);
    }
  }, []);
  
  useEffect(() => {
    localStorage.setItem('job', job);
  }, [job]);
  
  const handleJobChange = (selectedJob) => {
    setJob(selectedJob);
    localStorage.setItem('job', selectedJob);
    const newFormData = {
      ...formData,
      job: selectedJob,
    };
    setFormData(newFormData);
    localStorage.setItem('formData', JSON.stringify(newFormData));
};

  useEffect(() => {
    const storedFormData = localStorage.getItem('formData');
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);
      setFormData(parsedFormData);
      if (parsedFormData.job) {
        setJob(parsedFormData.job);
      }
      if (parsedFormData.name) {
        setName(parsedFormData.name);
      }
    }
  }, []);

  
  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);
  
 const handlePositionChange = (value) => {
  const newFormData = { ...formData, position: value };
 setFormData(newFormData);
 localStorage.setItem('formData', JSON.stringify(newFormData)); // Add this line
 };

  //const handlePasswordChange = (event) => {
    //setPassword(event.target.value);
  //};
  //const handlePasswordSubmit = (event) => {
    //event.preventDefault();
    // Check if the password is valid (e.g., compare with a stored password)
    //const storedPassword = '2424'; // Replace with your actual password
    //const isValid = password === storedPassword;
    //setIsPasswordValid(isValid);
    //if (isValid) {
     // fetchData();
   // } else {
     // console.log('Ignore pw - only for Evan');
   // }
  //};

const handleSubmitEdit = async () => {
  const docId = userActivityDetails.docId; // retrieve the document id
  if (!docId) {
    console.error("Document id is required for updating.");
    return;
  }

  // Validate the new start and end times
  if (!newStartTime || !newEndTime) {
    console.error("Both new start time and end time are required for editing.");
    return;
  }

  // Parse new start and end times as JavaScript Date objects
  const newStartDateTime = new Date(newStartTime);
  const newEndDateTime = new Date(newEndTime);

  // Calculate new duration
  const newDuration = calculateDuration(newStartDateTime, newEndDateTime);

  // Update the userActivityDetails state with new start time, end time, and duration
  setUserActivityDetails((prevState) => ({
    ...prevState,
    startTime: newStartTime,
    endTime: newEndTime,
    duration: newDuration,
  }));

  // Use Firestore's updateDoc function to update the specific document in the 'timeRecords' collection
  const docRef = doc(db, 'timeRecords', docId);
  await updateDoc(docRef, {
    startTime: newStartTime,
    endTime: newEndTime,
    duration: newDuration,
  });

  // Set isEditing to false to exit editing mode
  setIsEditing(false);
};

 

return (
  <div className="App">
    <header className="App-header">
      <h1>TimeTracker</h1>
    </header>
    <form className="App-form" onSubmit={handleSubmit(timerRunning ? stopTimer : startTimer)}>
      <JobSearch control={control} onJobChange={handleJobChange} />
      <div className="field-spacing">
        <label>
          <NameSelect control={control} onNameChange={handleNameChange} defaultValue={formData.name || ''} />
        </label>
        <label>
          <PositionSelect control={control} onPositionChange={handlePositionChange} defaultValue={formData.position || ''} />
        </label>
      </div>

      <button
        type="button"
        className={timerState}
        onClick={
          timerState === 'start' ? startTimer :
          timerState === 'stop' ? stopTimer : resetSession
        }
      >
        {timerState.charAt(0).toUpperCase() + timerState.slice(1)}
      </button>

      {userActivityDetails && <UserActivityDetails {...userActivityDetails} isEditing={isEditing} onStartChange={handleStartTimeChange} onEndChange={handleEndTimeChange} />}

      {isDetailsShown && (
  isEditing ? (
    <form onSubmit={handleSubmitEdit}>
      <input type="datetime-local" value={newStartTime} onChange={(e) => setNewStartTime(e.target.value)} />
      <input type="datetime-local" value={newEndTime} onChange={(e) => setNewEndTime(e.target.value)} />
      {isEditing && <button className="submit-edit-button" onClick={handleSubmitEdit}>Submit New Times</button>}
    </form>
  ) : (
    <button className="edit-button" onClick={() => setIsEditing(true)}>Edit Start/End Time</button>
  )
)}


    </form>

  </div>
);
}
export default TimeTracker;
